import { type PaymentsAppAvailableDomains, type Settings } from "./interfaces";

export const CallBackQueryParameter = "isPaymentCallback";
export const PaymentsAppInitQueryParam = "initQhpp";
export const PaymentsAppReadyEvent = "payment-app-ready";

export const WebAppMapping: Record<PaymentsAppAvailableDomains, Record<`${Settings["environment"]}-${Settings["channel"]}`, string>> = {
	"mybowlingpassport.com" : {
		"development-beta": "https://happy-grass-09f433a03.5.azurestaticapps.net",
		"development-stable": "https://calm-field-08cd05b03.5.azurestaticapps.net",
		"staging-beta": "https://payments-staging-beta.mybowlingpassport.com",
		"staging-stable": "https://payments-staging.mybowlingpassport.com",
		"production-beta": "https://payments-beta.mybowlingpassport.com",
		"production-stable": "https://payments.mybowlingpassport.com",
		"expo-beta": "https://payments-expo-beta.mybowlingpassport.com",
		"expo-stable": ""
	},
	"qubicaamf.com": {
		"development-beta": "https://happy-grass-09f433a03.5.azurestaticapps.net",
		"development-stable": "https://calm-field-08cd05b03.5.azurestaticapps.net",
		"staging-beta": "https://payments-staging-beta.qubicaamf.com",
		"staging-stable": "https://payments-staging.qubicaamf.com",
		"production-beta": "https://payments-beta.qubicaamf.com",
		"production-stable": "https://payments.qubicaamf.com",
		"expo-beta": "https://payments-expo-beta.qubicaamf.com",
		"expo-stable": ""
	},
	"azurestaticapps.net": {
		"development-beta": "https://happy-grass-09f433a03.5.azurestaticapps.net",
		"development-stable": "https://calm-field-08cd05b03.5.azurestaticapps.net",
		"staging-beta": "https://victorious-meadow-0df04410f.5.azurestaticapps.net",
		"staging-stable": "https://mango-island-0feb1860f.5.azurestaticapps.net",
		"production-beta": "https://purple-dune-0d0a87f0f.5.azurestaticapps.net",
		"production-stable": "https://salmon-coast-0af59f00f.5.azurestaticapps.net",
		"expo-beta": "https://black-bay-0eaba7d0f.5.azurestaticapps.net",
		"expo-stable": ""
	}
};
