
import { ApplicationInsights } from "@microsoft/applicationinsights-web";

import { PaymentsAppInitQueryParam } from "../../common/environment";
import { type ResultPayload, type Settings } from "../../common/interfaces";

async function settingsResolver() {
	const contextFileUrl = __LAUNCH_MODE__ === "Standard"
		? "/context.json"
		: `/settings/${__ENVIRONMENT__}-${__CHANNEL__}.json`;
	const response = await fetch(contextFileUrl, { method: "GET", cache: "no-cache" }).then((data) => data.json());
	return response as Settings;
}

function redirectForm(
	url: string,
	method: "GET" | "POST" | "PUT" | "PATCH" | "get" | "post" | "put" | "patch" = "POST",
	data?: object
) {
	if (method.toLowerCase() === "get" && !data)
		return location.replace(url);

	const form = document.createElement("form");
	form.style.display = "none";
	form.setAttribute("action", url);
	form.setAttribute("method", method.toLowerCase());
	let inputs = "";
	if (data) {
		Object.keys(data).forEach(field => {
			const value = (data as any)[field];
			inputs += `<input type="hidden" name="${field}" value="${value}" />`;
		});
		form.innerHTML = inputs;
	}
	document.body.appendChild(form);
	form.submit();
}

window.addEventListener("message", (e) => {
	if (e.data.command === "redirect") {
		const { url, method, data } = (e.data as any).payload;
		redirectForm(url, method, data);
	}
});

window.addEventListener("load", async() => {
	const isInit = location.href.indexOf(`?${PaymentsAppInitQueryParam}=`) > 0;
	if (isInit) {
		const settings = await settingsResolver();
		if (settings.appInsightsConnString) {
			const AppInsights = new ApplicationInsights({
				config: {
					connectionString: settings.appInsightsConnString,
					disableExceptionTracking: true,
					disableTelemetry: __LAUNCH_MODE__ == "DevelopSession"
				}
			});
			AppInsights.loadAppInsights();
		}
		if (window.opener)
			window.opener.postMessage("ready", "*");
		else
			window.parent?.postMessage("ready", "*");
	}
	else {
		const params = new URLSearchParams(window.location.search);
		const parsedParams = Object.fromEntries(params.entries());
		const result: ResultPayload = {
			providerReplied: true,
			providerData: parsedParams
		};
		if (window.opener)
			window.opener.postMessage(result, "*");
		else
			window.parent?.postMessage(result, "*");
	}
});
